<!-- <template>
  <div class="sidebar-container">
    <div class="menu">
      <ul class="menu-items">
        <li
          class="menu-title"
          :class="{
            current: currentRoute === 'components',
          }"
          @click="handleMenuTitleClick('components')"
        >
          Components
          <svg
            v-if="currentRoute === 'components'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-up"
          >
            <polyline points="18 15 12 9 6 15"></polyline>
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-down"
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </li>
        <li
          v-for="(element, index) in shownComponents"
          :key="'components--' + index"
          @click="handleSubItemClick('components', element)"
          :class="{ active: currentSubRoute === element }"
        >
          {{ element.replaceAll("-", " ") }}
        </li>
        <li
          class="menu-title"
          :class="{
            current: currentRoute === 'blocks',
          }"
          @click="handleMenuTitleClick('blocks')"
        >
          Blocks
          <svg
            v-if="currentRoute === 'blocks'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-up"
          >
            <polyline points="18 15 12 9 6 15"></polyline>
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-down"
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </li>
        <li
          v-for="(element, index) in shownBlocks"
          :key="'blocks--' + index"
          @click="handleSubItemClick('blocks', element)"
          :class="{ active: currentSubRoute === element }"
        >
          {{ element.replaceAll("-", " ") }}
        </li>
        <li
          class="menu-title"
          :class="{
            current: currentRoute === 'views',
          }"
          @click="handleMenuTitleClick('views')"
        >
          Views
          <svg
            v-if="currentRoute === 'views'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-up"
          >
            <polyline points="18 15 12 9 6 15"></polyline>
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-down"
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </li>
        <li
          v-for="(element, index) in shownViews"
          :key="'views--' + index"
          @click="handleSubItemClick('views', element)"
          :class="{ active: currentSubRoute === element }"
        >
          {{ element.replaceAll("-", " ") }}
        </li>
        <li
          class="menu-title"
          :class="{
            current: currentRoute === 'pages',
          }"
          @click="handleMenuTitleClick('pages')"
        >
          Pages
          <svg
            v-if="currentRoute === 'pages'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-up"
          >
            <polyline points="18 15 12 9 6 15"></polyline>
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-down"
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </li>
        <li
          v-for="(element, index) in shownPages"
          :key="'pages--' + index"
          @click="handleSubItemClick('pages', element)"
          :class="{ active: currentSubRoute === element }"
        >
          {{ element.replaceAll("-", " ") }}
        </li>
      </ul>
    </div>
  </div>
</template>-->

<template>
  <div class="sidebar-container">
    <div class="sidebar-menu sidebar-submenu">
      <ul class="menu-items" role="menu">
        <li
          class="menu-title"
          :class="{
            current: currentRoute === 'components',
          }"
          @click="handleMenuTitleClick('components')"
        >
          Components
        </li>

        <li
          v-for="(element, index) in shownComponents"
          :key="'components--' + index"
          @click="handleSubItemClick('components', element)"
          :class="{ active: currentSubRoute === element }"
        >
          {{ element.replaceAll("-", " ") }}
        </li>

        <li
          class="menu-title"
          :class="{
            current: currentRoute === 'blocks',
          }"
          @click="handleMenuTitleClick('blocks')"
        >
          Blocks
        </li>
        <li
          v-for="(element, index) in shownBlocks"
          :key="'blocks--' + index"
          @click="handleSubItemClick('blocks', element)"
          :class="{ active: currentSubRoute === element }"
        >
          {{ element.replaceAll("-", " ") }}
        </li>
        <li
          class="menu-title"
          :class="{
            current: currentRoute === 'views',
          }"
          @click="handleMenuTitleClick('views')"
        >
          Views
        </li>
        <li
          v-for="(element, index) in shownViews"
          :key="'views--' + index"
          @click="handleSubItemClick('views', element)"
          :class="{ active: currentSubRoute === element }"
        >
          {{ element.replaceAll("-", " ") }}
        </li>
        <li
          class="menu-title"
          :class="{
            current: currentRoute === 'pages',
          }"
          @click="handleMenuTitleClick('pages')"
        >
          Pages
        </li>
        <li
          v-for="(element, index) in shownPages"
          :key="'pages--' + index"
          @click="handleSubItemClick('pages', element)"
          :class="{ active: currentSubRoute === element }"
        >
          {{ element.replaceAll("-", " ") }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TempLeftNav",
  data: function() {
    return {
      currentRoute: "",
      currentSubRoute: "",
      components: [
        "accordions",
        "alerts",
        "buttons",
        "card",
        // "carousel",
        "checkbox",
        // "date-picker",
        "dividers",
        "dropdown",
        "input",
        "list",
        "modal",
        "radio-button",
        "stepper",
        "tabs",
        "textarea",
        "tiles",
      ],
      blocks: [
        "primary-header",
        "footer"
      ],
      views: [],
      pages: [
        "401-error",
        "403-error",
        "404-error",
        "500-error",
        "maintenance",
      ],
    };
  },
  created: function() {
    this.currentRoute = this.$router.currentRoute.path.split("/")[3];
    if (typeof this.$router.currentRoute.path.split("/")[4] !== "undefined") {
      this.currentSubRoute = this.$router.currentRoute.path.split("/")[4];
    }
  },
  methods: {
    handleMenuTitleClick: function(route) {
      if (this.currentRoute != route) {
        this.$router.push(`/design-system/building-blocks/${route}`);
      }
    },
    handleSubItemClick: function(route, subItem) {
      this.$router.push(`/design-system/building-blocks/${route}/${subItem}`);
    },
  },
  computed: {
    shownComponents: function() {
      if (this.currentRoute === "components") {
        return this.components;
      }
      return [];
    },
    shownBlocks: function() {
      if (this.currentRoute === "blocks") {
        return this.blocks;
      }
      return [];
    },
    shownViews: function() {
      if (this.currentRoute === "views") {
        return this.views;
      }
      return [];
    },
    shownPages: function() {
      if (this.currentRoute === "pages") {
        return this.pages;
      }
      return [];
    },
  },
};
</script>

<style scoped>
.left-nav__item {
  background-color: #eaebf0;
  border-bottom: 1px solid #f9f8fe;
  padding: 20px;
  padding-left: 80px;
  /* padding-bottom: 0; */
  text-transform: capitalize;
  font-weight: bold;
  cursor: pointer;
  font-size: 18px;
  position: relative;
}
.left-nav__container {
  min-width: 315px;
  margin-left: -40px;
  background-color: white;
  /* min-height: 1800px; */
  min-height: 1200px;
}
.feather {
  position: absolute;
  right: 20px;
  z-index: 2;
}
</style>
