var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-container"},[_c('div',{staticClass:"sidebar-menu sidebar-submenu"},[_c('ul',{staticClass:"menu-items",attrs:{"role":"menu"}},[_c('li',{staticClass:"menu-title",class:{
          current: _vm.currentRoute === 'components',
        },on:{"click":function($event){return _vm.handleMenuTitleClick('components')}}},[_vm._v(" Components ")]),_vm._l((_vm.shownComponents),function(element,index){return _c('li',{key:'components--' + index,class:{ active: _vm.currentSubRoute === element },on:{"click":function($event){return _vm.handleSubItemClick('components', element)}}},[_vm._v(" "+_vm._s(element.replaceAll("-", " "))+" ")])}),_c('li',{staticClass:"menu-title",class:{
          current: _vm.currentRoute === 'blocks',
        },on:{"click":function($event){return _vm.handleMenuTitleClick('blocks')}}},[_vm._v(" Blocks ")]),_vm._l((_vm.shownBlocks),function(element,index){return _c('li',{key:'blocks--' + index,class:{ active: _vm.currentSubRoute === element },on:{"click":function($event){return _vm.handleSubItemClick('blocks', element)}}},[_vm._v(" "+_vm._s(element.replaceAll("-", " "))+" ")])}),_c('li',{staticClass:"menu-title",class:{
          current: _vm.currentRoute === 'views',
        },on:{"click":function($event){return _vm.handleMenuTitleClick('views')}}},[_vm._v(" Views ")]),_vm._l((_vm.shownViews),function(element,index){return _c('li',{key:'views--' + index,class:{ active: _vm.currentSubRoute === element },on:{"click":function($event){return _vm.handleSubItemClick('views', element)}}},[_vm._v(" "+_vm._s(element.replaceAll("-", " "))+" ")])}),_c('li',{staticClass:"menu-title",class:{
          current: _vm.currentRoute === 'pages',
        },on:{"click":function($event){return _vm.handleMenuTitleClick('pages')}}},[_vm._v(" Pages ")]),_vm._l((_vm.shownPages),function(element,index){return _c('li',{key:'pages--' + index,class:{ active: _vm.currentSubRoute === element },on:{"click":function($event){return _vm.handleSubItemClick('pages', element)}}},[_vm._v(" "+_vm._s(element.replaceAll("-", " "))+" ")])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }